.nav-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  position: fixed;
  background-color: black;
  padding: 1rem 100%;
  box-shadow: none;
  z-index: 5;
}