.toggle {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  height: 4rem;
  width: 100%;
  border: none;
  background-color: #1a1926;
  box-shadow: 0.1rem 0.1rem 0rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  padding: 0 1.2rem 0 1rem;
  position: relative;
  z-index: 3;
  margin-bottom: 0.2rem;
  cursor: pointer;
}

.project-title {
  text-align: left;
  width: 100%;
}

.dropdown-arrow {
  text-align: right;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 800;
  color: whitesmoke;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.collapsible {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
}

.collapsible > div {
  overflow: hidden;
}

.project-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}