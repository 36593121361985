.tech-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: none;
  padding: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
  width: 100%;
}

.tech-row {
  display: flex;
  flex-direction: row;
  margin: 1.5rem 0;
}

.tech-icon {
  font-size: 3rem;
  text-align: center;
  padding: 1rem;
  border: 0.1rem solid black;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.8s ease 0s;
}

.tech-icon-wrapper {
  margin: 0 1.1rem;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .tech-icon {
    font-size: 2rem;
  }

  .tech-icon-wrapper {
    margin: 0 0.2rem;
  }

  .tech-icon {
    padding: 0.5rem;
  }
}