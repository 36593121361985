.intro-paragraph {
  align-items: left;
  text-align: left;
  background-color: none;
  padding: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
  width: 100%;
  font-family: 'Manrope', sans-serif;
}

.intro-text {
  padding: 0 1rem;
  font-size: 1rem;
}

@media screen and (max-width: 600px) {
  .intro-paragraph {
    align-items: center;
    text-align: center;
  }

  .intro-text {
    padding: 0 0.5rem;
  }
}