@import url('https://fonts.googleapis.com/css2?family=Croissant+One&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: whitesmoke;
}

h1 {
  margin: 0;
}

p {
  font-size: 0.9rem;
}

a {
  color: whitesmoke;
  text-decoration: none;
}

button {
  color: whitesmoke;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 50rem;
  font-family: 'Manrope', sans-serif;
  /* font-family: 'Courier New', Courier, monospace; */
}

@media screen and (max-width: 600px) {
  .main {
    width: 90vw;
  }
}