.resume-page {
  margin-top: 7rem;
}

.custom-doc:after {
  content: "";
  background-color: #ffe54c;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 1rem;
  left: 1rem;
}

.download-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 3rem 0;
}

.download-button {
  margin: 0 0.5rem;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  padding: 0.6rem 1.25rem;
  outline: 0;
  border: 0.1rem solid black;
  border-radius: 2rem;
  cursor: pointer;
  position: relative;
  background-color: #1a1926;
  transition: 0.5s;
  width: max-content;
}

.download-button:hover {
  background-color: #ffe54c;
}

@media screen and (max-width: 600px) {
  .download-button {
    font-size: 0.7rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    font-size: 0.8rem;
    width: 11rem;
  }

  .download-button-container {
    flex-direction: column;
    margin: 1.5rem 0;
  }
}