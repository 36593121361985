.social-media-banner {
  display: inline-block;
  margin: 1rem 0 0 0;
}

.icon-button {
  margin: 0 1.5rem;
  position: relative;
  z-index: 4;
  fill: whitesmoke;
}

.social-media-icons {
  width: 4rem;
  height: 4rem;
}

.icon-button:after {
  content: "";
  background-color: #1a1926;
  border-radius: 5%;
  width: 120%;
  position: absolute;
  height: 200%;
  top: -1rem;
  left: 0.4rem;
  transition: 0.2s;
  z-index: -1;
}

.icon-button:hover:after {
  top: -2rem;
  left: 0rem;
}

@media screen and (max-width: 600px) {
  .social-media-icons {
    width: 2.5rem;
    height: 2.5rem;
  }

  .icon-button {
    margin: 0 1rem;
  }

  .icon-button:after {
    width: 130%;
    height: 120%;
    top: -1rem;
    left: 1rem;
  }

  .icon-button:after {
    top: 0;
    left: 0.3rem;
  }
}